<template>
  <div>
    <div class="all-container">
      <div class="top-banner">
        <div class="container flex-center">
          <el-image :src="topBanner[lan]" style="width: 86%" lazy></el-image>
        </div>
        <!-- <div class="coverage">
          <div class="banner-title1">{{ $t('餐厅现金优惠券') }}<span>¥500</span></div>
        </div> -->
      </div>
    </div>
    <div class="container">
      <div class="list-top">
        <div class="list-top-title">{{ $t('精选餐厅') }}</div>
        <!-- <div class="list-top-right">
          <div>{{ $t('关键字') }}</div>
          <el-input v-model="searchForm.keywords" size="small" class="list-key-inp" @keyup.enter.native="searchHandle()"/>
          <el-button size="small" class="list-key-btn el-icon-search" @click="searchHandle()"/>
        </div> -->
      </div>
      <div v-if="dataList && dataList.length > 0" v-loading="dataListLoading" style="margin-top: 30px">
        <listPane ref="listPane" />
      </div>
      <div v-else v-loading="dataListLoading">
        <div class="list-not">
          {{ $t('列表暂无数据') }}
        </div>
      </div>
      <!-- <div class="list-page">
        <el-pagination :current-page="searchForm.page" :page-size="searchForm.pageSize" :total="sizeAll" background layout="prev, pager, next" @current-change="currentChangeHandle" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { storeRecommendList } from '@/api/store'
import { formatMoney } from '@/utils/money'
import { weeks, storeService, storeShopType } from '@/utils/public_data'
import listPane from '@/views_pc/list/index'

export default {
  components: { listPane },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0,5)
    }
  },
  data() {
    return {
      searchForm: {
        keywords: '',
        page: 1,
        pageSize: 6
      },
      sizeAll: 0,
      dataList: [],
      service: storeService(),
      weeks: weeks(),
      imglist1: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      imglist2: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fa0.att.hudong.com%2F30%2F29%2F01300000201438121627296084016.jpg&refer=http%3A%2F%2Fa0.att.hudong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1614823486&t=a246fc320a07d6426b9d99dac10e8553',
      shopLogo: require('@/assets/images/shop.png'),
      forward: require('@/assets/images/forward.png'),
      notImg: require('@/assets/images/not-image.png'),
      topBanner: { 
        'ja': require('@/assets/images/banner-jp.png'),
        'en': require('@/assets/images/banner-en.png'),
        'zh': require('@/assets/images/banner-zh.png'),
        'ko': require('@/assets/images/banner-ko.png'),
      },
      shopType: storeShopType(),
      thumbsSwiper: {},
      swiper: {},
      dataListLoading: false
    }
  },
  computed: {
    lan() {
      return localStorage.getItem('locale')
    }
  },
  watch: {
    // dataList: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.initSwiper()
    //     }
    //   },
    //   deep: true
    // },
    lan(newVal) {
      if (newVal) {
        console.log(newVal)
        this.searchHandle()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    initSwiper(index) {
      this.thumbsSwiper[index] = new Swiper(`.img-thumbs${index}`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        loop: false,
        watchSlidesVisibility: true,/*避免出现bug*/
      })
      this.swiper[index] = new Swiper(`.img-top${index}`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next_${index}`,
        prevButton: `.swiper-button-prev_${index}`,
        speed: 1000,
        loop: false,
        controller:{
          control: this.thumbsSwiper[index],
        },
        thumbs: {
          swiper: this.thumbsSwiper[index],
        }
      })
    },
    init() {
      this.getDataList()
    },
    handlerBMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      // console.log('22222', BMap.Point(this.detailData.longitude, this.detailData.latitude))
      this.$set(this.center, 'lat', this.detailData.latitude)
      this.$set(this.center, 'lng', this.detailData.longitude)
      console.log(this.center)
    },
    searchHandle() {
      this.searchForm.page = 1
      this.getDataList()
    },
    getDataList() {
      // 获取数据
      this.dataListLoading = true
      storeRecommendList().then(response => {
        if(response && response.data) {
          this.dataList = response.data
          this.$nextTick(() => {
            if (this.dataList && this.dataList.length > 0) {
              this.$refs.listPane.init(this.dataList)
            }
          })
          this.dataListLoading = false
        } else {
          this.dataList = []
          this.sizeAll = 0
          this.dataListLoading = false
        }
      }).catch(() => {
        this.dataList = []
        this.sizeAll = 0
        this.dataListLoading = false
      })
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.page = val
      this.getDataList()
    },
    // 进入店铺
    detailHandle(id) {
      this.$router.push({ path: '/detail', query: { id: id }})
    }
  }
}
</script>
<style lang='scss' scoped>
.list-top {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list-top-title {
    height: 20px;
    // line-height: 20px;
    padding-left: 20px;
    border-left: 4px solid #E7B530;
    font-size: 24px;
    font-weight: 600;
  }
  .list-top-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .list-key-inp {
      margin-left: 20px;
      width: 200px;
    }
    .list-key-btn {
      margin-left: -2px;
      background: #E7B530;
      color: #000;
      font-weight: 600;
      zoom: -10;
    }
  }
}
.list-not {
  width: 100%;
  min-height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  padding-top: 20px;
  border-top: 1px solid #DCDFE6;
  width: 100%;
  min-height: 260px;
  &:hover {
    transform: translateY(-1px);
    z-index: 20;
    background: rgba(240,240,240,0.3);
  }
  .swiper-box {
    width: 180px;
    min-height: 250px;
    .list-left-image {
      width: 180px;
      height: 180px;
    }
    .list-image-thumbs {
      margin-top: 8px;
      width: 60px;
      height: 54px;
    }
  }
  .item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item-title-content {
      display: flex;
      align-items: center;
      .list-title-content {
        ::v-deep .el-link {
          font-size: 24px;
          font-weight: 400;
          color: #000;
          &:hover {
            color: #E7B530;
          }
        }
        margin-left: 10px;

      }
    }
    .shop-log {
      width: 28px;
    }
    .forward-logo {
      width: 28px;
    }
  }
  .list-rate {
    margin: 8px 0 10px;
    ::v-deep .el-rate__icon {
      font-size: 24px;
    }
    ::v-deep .el-rate__text {
      font-size: 18px;
    }
  }
  .list-service {
    margin-top: 12px;
    display: flex;
    .service-item {
      display: flex;
      .service-logo {
        width: 16px;
        margin-right: 10px;
      }
      .service-content {
        font-size: 12px;
      }
    }
  }
  .list-cost {
    margin-top: 20px;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    .cost-text {
      color:  #F56C6C;
    }
  }
  .list-c2 {
    display: flex;
    justify-content: space-between;
    .cost-btn {
      background: #E7B530;
      color: #000;
      font-size: 14px;
      width: 120px;
      height: 40px;
    }
  }
  .cost-discount {
      margin: 16px 0 ;
      width: 80%;
      display: flex;
      justify-content: flex-start;
      .cost-title {
        margin-top: 0px;
        margin-right: 20px;
        font-size: 14px;
        .const-red-content {
          color: #F56C6C;
        }
      }
    }
  .list-info {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    .list-info-item {
      margin-right: 20px;
    }
  }
  .list-detail {
    margin-top: 12px;
    display: flex;
    .detail-content {
      // margin-top: 10px;
      // width: 700px;
      flex: 1;
      // height:20px;
      line-height: 20px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .detail-btn {
      width: 100px;
    }
  }
}
.list-page {
  text-align: center;
  .el-pagination {
    ::v-deep .btn-prev {
      background-color: #fff;
      border: 1px solid #DCDFE6;
    }
    ::v-deep .btn-next {
      background-color: #fff;
      border: 1px solid #DCDFE6;
    }
    ::v-deep .el-pager {
      .number {
        background-color: #fff;
        color: #909399;
        border: 1px solid #DCDFE6;
      }
      li:not(.disabled).active {
        background-color: #E7B530;
      }
    }
  }
}
.not-image {
  width: 180px;
  height: 180px;
  background: rgb(244,244,244);
}
.not-thumbs-box {
  margin-top: 8px;
  display: flex;
  .not-image2 {
    margin-right: 10px;
    background:rgb(244,244,244);
    width: 54px;
    height: 54px;
  }
}
.mr-20 {
  margin-right: 20px;
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  background-color: #E7B530;
  z-index: 1;
  .coverage{
    background: #fff;
    color: #4f2f24;
    padding: 30px;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    .banner-title1 {
      font-size: 42px;
      font-weight: 600;
      padding: 60px 180px;
      border: 2px solid #4f2f24;
    }
    .banner-title1 span {
      font-size: 64px;
      font-weight: 800;
      margin-left: 16px;
    }
  }
}
.flex-center {
  display: flex;
  justify-content: center;
}
</style>
